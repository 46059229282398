import { faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useQuery } from 'react-query'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import LogData from '../../../components/LogData'

const RegenerateModal = ({ onSuccess }) => {
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [refetchInterval, setRefetchInterval] = useState(null)
    const handleClose = () => setShow(false)

    const axiosPrivate = useAxiosPrivate();

    const { data: logData } = useQuery("logs", async () => {
        return await axiosPrivate.post(`/admin/logs`, { scope: 'regenerate' }).then(res => res.data);
    }, {
        refetchInterval
    });

    const handleRegenerate = async (e) => {
        e.preventDefault();
        setLoading(true)
        setRefetchInterval(1000);
        await axiosPrivate.post(`/admin/veneers/regenerate`).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setRefetchInterval(null);
    }, [show])

    return (
        <>
            <button type='button' className='btn btn-link text-danger' onClick={(e) => {
                e.preventDefault()
                setShow(true)
            }}>
                <FontAwesomeIcon icon={faRefresh} size="2x" />
            </button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {loading ? (
                        <>
                            <LoadingSpinner />
                            Please wait ...
                            <LogData logs={logData} />
                        </>
                    ) : (
                        <>
                            The regeneration of all scene veneers will be initiated by this action and it could take a while. <br />
                            Please use this option only if a new room has been added to the app. <br />
                            <div className="text-danger mt-1">
                                Caution: the regeneration process will put a significant strain on the
                                server resources and as a result, the app&apos;s performance may be slowed down temporarily.
                            </div>

                        </>
                    )}

                    {!loading && logData ? (
                        <LogData logs={logData} />
                    ) : ""}

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        disabled={loading}
                        onClick={handleRegenerate}
                    >
                        Yes, regenerate
                    </Button>
                    <Button
                        variant="primary"
                        disabled={loading}
                        onClick={(e) => {
                            e.preventDefault()
                            setShow(false)
                        }}
                    >
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RegenerateModal
