import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import axios from './../../api/axios'
import RoomOptions from './Room/RoomOptions'
import RoomView from './Room/RoomView'
import { useWindowContext } from '../../context/WindowContextProvider'
const API_BASE_URL = process.env.REACT_APP_API

const Room = () => {
  const { slug } = useParams()
  const { sidebar } = useWindowContext()

  const [selectedOptions, setSelectedOptions] = useState({
    slug,
    roomId: '',
    door_type: '',
    veneer_type: 6,
    veneer: '',
    layout: '',
  })
  const [selectedRoom, setSelectedRoom] = useState()
  const loading = false

  const {
    // status: roomOptionsStatus,
    // error: roomOptionsError,
    data: roomOptionsData,
  } = useQuery({
    queryKey: ['room', selectedOptions],
    queryFn: async ({ queryKey }) => {
      const selected = queryKey[1] ?? null

      return await axios
        .post(`${API_BASE_URL}/api/rooms/options`, {
          slug,
          selected,
        })
        .then((res) => res.data)
    },
  })

  const onClickOption = async (scope, scope_id) => {
    console.log('onClickOption', scope, scope_id)

    if (scope === 'veneer_type') {
      console.log('reset')
      // reset
      setSelectedOptions({
        ...selectedOptions,
        [scope]: scope_id,
        veneer: '',
        layout: '',
      })
    } else {
      setSelectedOptions({ ...selectedOptions, [scope]: scope_id })
    }
  }

  useEffect(() => {
    // set first option default for each filter
    if (roomOptionsData) {
      if (selectedOptions.layout === '') {
        setSelectedOptions({
          ...selectedOptions,
          layout: roomOptionsData.textureTypes[0].id,
        })
      }
      if (selectedOptions.veneer === '') {
        setSelectedOptions({
          ...selectedOptions,
          veneer: roomOptionsData.veneers[0].id,
        })
      }
      if (selectedOptions.veneer_type === '') {
        setSelectedOptions({
          ...selectedOptions,
          veneer_type: roomOptionsData.veneerTypes[0].id,
        })
      }
      if (selectedOptions.door_type === '' && roomOptionsData.doorTypes) {
        setSelectedOptions({
          ...selectedOptions,
          door_type: roomOptionsData.doorTypes[0].id,
        })
      }
    }
  }, [roomOptionsData, selectedOptions])

  return (
    <>
      {!loading ? (
        <RoomView
          selectedOptions={selectedOptions}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
        />
      ) : (
        <div className="room-spinner">
          <LoadingSpinner />
          <div>Please wait, we are baking your image.</div>
        </div>
      )}

      <aside id="sidebar" style={{ width: sidebar?.width }}>
        <div className="logo">
          <a href="/" title="Back to home">
            <img
              src="/assets/logo-white.png"
              alt="DF Richards"
              width={215}
              height={46}
            />
          </a>
          <div className="logo-sub">Veneers Visualiser</div>
        </div>
        <div className="room-options">
          <Link
            to={'/'}
            className="return"
            title="Return to room selector page"
          >
            <span>❮</span>Return to room selector page
          </Link>

          <div className="separator-helper"></div>

          <div className="text-center text-danger border d-none">
            sidebar.width: {sidebar?.width}
          </div>

          <RoomOptions
            roomOptionsData={roomOptionsData}
            selectedOptions={selectedOptions}
            onClick={onClickOption}
          />
        </div>
      </aside>
    </>
  )
}

export default Room
