import React, { useState, useCallback } from 'react'
// import { Outlet } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Helmet } from 'react-helmet'
import { Link, Outlet } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from '../../../api/axios'
import './../../../fonts/futura-medium-bt.ttf'
import './../../../fonts/futura-light-bt.ttf'
import './../guest.scss'
import './../_scrollbar.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { useScrollDirection } from '../../../hooks/useScrollDirection'

const queryClient = new QueryClient()

const Layout = () => {
  const scrollDirection = useScrollDirection()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [email, setEmail] = useState();
  const [success, setSuccess] = useState(false)
  const [errors, setErrors] = useState(null)

  const onSubscribe = async (e) => {
    e.preventDefault()
    handleReCaptchaVerify();
  }
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('submit_subscribe_form')
    const payload = {
      email,
      recaptcha: token,
    }

    try {
      await axios.post(`/api/subscribe`, payload);
      setSuccess(true)
    } catch (err) {

      if (err?.response?.data?.errors) {
        setErrors(err?.response?.data?.errors)
      } else {
        alert('Something went wrong. Please try again later!')
      }
    }
  }, [executeRecaptcha, email])



  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <title>Veneer Visualiser</title>
      </Helmet>

      <div id="Guest">
        <nav
          className={`navbar navbar-expand-lg navbar-light fixed-top ${scrollDirection === 'down' ? 'nav-fadeOut' : 'nav-fadeIn'
            }`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img src="/assets/logo-white.png" alt="Logo" width={215} height={46} />
            </Link>
            <div className="navbar-links collapse navbar-collapse d-flex">
              <div className="d-flex justify-content-center gap-3 ms-3">
                <a
                  href="https://www.facebook.com/dfrveneer"
                  className="text-black"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size="1x"
                    target="_blank"
                    rel="noreferrer"
                  />
                </a>
                <a
                  href="https://www.instagram.com/dfrveneer/?hl=en"
                  className="text-black"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} size="1x" />
                </a>
                <a
                  href="https://twitter.com/dfrveneer"
                  className="text-black"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
                <a
                  href="https://www.youtube.com/@dfrichards"
                  className="text-black"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faYoutube} size="1x" />
                </a>
              </div>

              <ul className="navbar-nav mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    to="/contact"
                    className="nav-link fs-6 fw-semibold text-black"
                  >
                    contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <Outlet />

        <footer className="mt-5 py-5 bg-black text-white bubble-parent">
          <div className="container">

            <div className="bubble">
              <svg
                className=""
                width="244"
                height="297"
                viewBox="0 0 244 297"
                fill="none" xmlns="http://www.w3.org/2000/svg"
                style={{ transform: "translate3d(0px, -15px, 0px)" }}
              >
                <circle cx="200" cy="200" r="200" fill="#202020"></circle>
              </svg>
            </div>

            <div className="row">
              <div className="col-lg-4 col-xs-12">
                <img src="./assets/logo.png" alt="Logo" width={215} height={46} />
                <div className="mt-5">
                  The visualiser is brought to you by DF Richards.
                  To order free veneer samples please visit <a href="https://www.dfrichards.co.uk" target="_blank" rel="noreferrer">www.dfrichards.co.uk</a>
                </div>

                <div className="mt-5"></div>

                <div>01268 523 611</div>
                <div>marketing@dfrichards.co.uk</div>
              </div>
              <div className="col-lg-8 col-xs-12">
                <form
                  className="newsletter-form"
                  onSubmit={onSubscribe}
                >

                  <label className="mt-3">
                    Your Email <span className="text-danger">*</span>
                  </label>

                  <div className="d-flex">
                    <input type="text" placeholder="your-name@example.com" onChange={(e) => setEmail(e.target.value)} required />



                    <div className="ms-1">
                      <button type="submit" className="btn btn-dark">
                        Send
                      </button>
                    </div>
                  </div>

                  {success ? (
                    <div className="text-success">Thank you for getting in touch!</div>
                  ) : ""}

                  {errors && (
                    <div className="text-danger">
                      {Object.keys(errors).map((key) => (
                        <p key={key}>{errors[key][0]}</p>
                      ))}
                    </div>
                  )}

                </form>
              </div>
            </div>
          </div>
        </footer>

        <div className="bubble-bg"></div>
      </div>
    </QueryClientProvider>
  )
}

export default Layout
