import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import './VeneerPicker.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { Form } from 'react-bootstrap'

const VeneerPicker = ({ veneers, onClick, selected }) => {
  const [show, setShow] = useState(false)
  const [filteredVeneers, setFilteredVeneers] = useState([])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSearch = (e) => {
    let searchString = e.target.value

    if (!searchString || searchString.trim().length < 0) {
      setFilteredVeneers([...veneers])
      return
    }

    searchString = searchString.trim().toLowerCase()

    setFilteredVeneers([
      ...veneers.filter((veneer) =>
        veneer.species.toLowerCase().includes(searchString)
      ),
    ])
  }

  const selectedName =
    veneers?.find((item) => item.id === selected)?.name ?? 'Choose'

  useEffect(() => {
    setFilteredVeneers([...veneers])
  }, [show, veneers])

  return (
    <>
      <Button
        className="btn btn-dark btn-block border border-1 mb-1 rounded-pill"
        onClick={handleShow}
      >
        {selectedName}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="veneer-picker modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Choose a veneer
            <span className="ms-1 fs-5">
              <OverlayTrigger
                className="ms-2"
                placement="bottom"
                delay={{ show: 250, hide: 250 }}
                overlay={
                  <Tooltip id={`tooltip-bottom`}>
                    Hover over the veneers below to show their name.
                    Alternatively you can use the search on the right hand side.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </OverlayTrigger>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-1">
              <Form.Control
                type="text"
                placeholder="Search ..."
                onKeyUp={handleSearch}
              />
            </Form.Group>
          </Form>

          {filteredVeneers.length > 0 ? (
            <div className="thumbs-container">
              {filteredVeneers.map((item) => (
                <div
                  key={item.id}
                  className={
                    'veneer-thumb ' +
                    (item.id === selected ? 'active shadow' : '')
                  }
                  onClick={(e) => {
                    onClick('veneer', item.id)
                    setShow(false)
                  }}
                >
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 250 }}
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>{item.species}</Tooltip>
                    }
                  >
                    <img
                      src={item.thumb_url}
                      alt={item.name}
                      title={item.species}
                    />
                  </OverlayTrigger>

                  <div className="text-center">
                    <small>{item.code}</small>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center fst-italic mt-3">Nothing found ;(</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default VeneerPicker
