import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { LoadingSpinner } from './../../components/LoadingSpinner'

export const Dashboard = () => {
  const [stats, setStats] = useState({
    veneersCount: 0,
    enquiresCount: 0,
  })
  const [loading, setLoading] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()
    setLoading(true)

    const getVeneers = async () => {
      try {
        const response = await axiosPrivate.get('/admin/dashboard/stats', {
          signal: controller.signal,
        })
        isMounted && setStats(response.data)
        setLoading(false)
      } catch (err) {
        console.log(err)
      }
    }

    getVeneers()

    return () => {
      isMounted = false
      controller.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
      </div>

      <div className="row">
        <div className="col-xl-6 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Veneers
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {loading ? <LoadingSpinner /> : stats.veneersCount}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                    Enquires
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">
                    {loading ? <LoadingSpinner /> : stats.enquiresCount}
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
