import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { Alert, Button, Card, Form } from 'react-bootstrap'
import { axiosPrivate } from '../../../api/axios'

const BASE_URL = process.env.REACT_APP_API

const VeneerTypesForm = ({ edit, onSave }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [formData, setFormData] = useState({
    name: '',
  })

  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
    setError(false)

    try {
      if (formData.id) {
        await axiosPrivate.patch(
          `${BASE_URL}/admin/veneer-types/${formData.id}`,
          formData
        )
      } else {
        await axiosPrivate.post(`${BASE_URL}/admin/veneer-types`, formData)
      }
      onSave()
    } catch (err) {
      console.dir(err)

      if (err.data) {
        setError(err.data)
      } else if (err.response.data.errors) {
        setError(err.response.data.errors)
      } else if (err.response.data.message) {
        setError(err.response.data.message)
      } else {
        setError('Unknown error!')
      }
      // setVeneer(null)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (edit) {
      setFormData({ ...edit })
    }
  }, [edit])

  return (
    <div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Veneer Types</h1>
        <button
          className="btn btn-link"
          onClick={(e) => {
            e.preventDefault()
            onSave(false)
          }}
        >
          <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" />
        </button>
      </div>

      <div className="bg-white p-3">
        <Form onSubmit={onSubmit}>
          <Card className="mt-3 shadow">
            <Card.Header>
              <Card.Title className="mb-1">
                {formData.id ? 'Edit veneer type' : 'Add veneer type'}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {!loading ? (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      placeholder="Name"
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({ ...formData, name: e.target.value })
                      }}
                    />
                  </Form.Group>
                </>
              ) : (
                <LoadingSpinner />
              )}

              {error ? (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              ) : (
                ''
              )}
            </Card.Body>
            <Card.Footer>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </div>
  )
}

export default VeneerTypesForm
