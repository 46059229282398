import React, { useState } from 'react'

import { Card, Button, Alert } from 'react-bootstrap'
import { axiosPrivate } from '../../../api/axios'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import VeneerRenders from '../VeneerRenders'
const BASE_URL = process.env.REACT_APP_API

const VeneerGenerate = ({ veneer, setVeneer }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleGenerate = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError(false)

    try {
      const res = await axiosPrivate.post(BASE_URL + '/admin/generate', {
        veneerId: veneer.id,
      })

      setVeneer((state) => {
        return {
          ...state,
          rooms: res.data?.rooms?.rooms,
        }
      })
    } catch (err) {
      setError(err?.response?.data?.errors.join(' '))
    } finally {
      setLoading(false)
    }
  }

  // console.log('VeneerGenerate: veneer', veneer)

  return (
    <Card className="mt-3 shadow">
      <Card.Header>
        <Card.Title className="mb-0">Result</Card.Title>
      </Card.Header>
      <Card.Body>
        {!loading ? <VeneerRenders veneer={veneer} /> : <LoadingSpinner />}
        {error ? (
          <Alert className="mt-3" variant="danger">
            {error}
          </Alert>
        ) : (
          ''
        )}
      </Card.Body>
      <Card.Footer>
        <Button variant="primary" onClick={handleGenerate} disabled={loading}>
          Generate
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default VeneerGenerate
