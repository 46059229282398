import React, { useEffect, useRef, useState } from "react";
import { useWindowContext } from "../context/WindowContextProvider";

const ImageMagnifier = ({
    src,
    width,
    height,
    magnifierHeight = 100,
    magnifierWidth = 100,
    zoomLevel = 1.5,
    square = false
}) => {
    const [imgLoaded, setImgLoading] = useState(false);
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);
    const imageRef = useRef();
    const { sidebar, setSidebar } = useWindowContext();

    useEffect(() => {

        if (imgLoaded === false) {
            // console.log('image is not loaded yet');
            return;
        }

        const onResize = (e) => {
            const clientWidth = document.documentElement.clientWidth;
            const imgWidth = imageRef.current.clientWidth;
            let sidebarWidth = clientWidth - imgWidth;

            if (sidebarWidth < 250) {
                sidebarWidth = 250;
            }

            setSidebar({...sidebar, width: sidebarWidth});

            // console.log('clientWidth', clientWidth);
            // console.log('imgWidth', imgWidth);
            // console.log('sidebarWidth', sidebarWidth);
        }
        window.addEventListener('resize', onResize)
        onResize();
        return () => {
          window.removeEventListener('resize', onResize)
        }
      }, [imgLoaded])


    return (
        <div
            style={{
                position: "relative",
                height: height,
                width: width
            }}
        >
            <img
                ref={imageRef}
                src={src}
                style={{ height: height, width: width }}
                onLoad={() => setImgLoading(true)}
                onMouseEnter={(e) => {
                    // update image size and turn-on magnifier
                    const elem = e.currentTarget;
                    const { width, height } = elem.getBoundingClientRect();
                    setSize([width, height]);
                    setShowMagnifier(true);
                }}
                onMouseMove={(e) => {
                    // update cursor position
                    const elem = e.currentTarget;
                    const { top, left } = elem.getBoundingClientRect();

                    // calculate cursor position on the image
                    const x = e.pageX - left - window.pageXOffset;
                    const y = e.pageY - top - window.pageYOffset;
                    setXY([x, y]);
                }}
                onMouseLeave={() => {
                    // close magnifier
                    setShowMagnifier(false);
                }}
                alt={"img"}
            />

            <div
                style={{
                    display: showMagnifier ? "" : "none",
                    position: "absolute",
                    borderRadius: square ? "" : "50%",

                    // prevent magnifier blocks the mousemove event of img
                    pointerEvents: "none",
                    // set size of magnifier
                    height: `${magnifierHeight}px`,
                    width: `${magnifierWidth}px`,
                    // move element center to cursor pos
                    top: `${y - magnifierHeight / 2}px`,
                    left: `${x - magnifierWidth / 2}px`,
                    opacity: "1", // reduce opacity so you can verify position
                    border: "1px solid lightgray",
                    backgroundColor: "white",
                    backgroundImage: `url('${src}')`,
                    backgroundRepeat: "no-repeat",

                    //calculate zoomed image size
                    backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,

                    //calculate position of zoomed image.
                    backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                    backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
                }}
            ></div>
        </div>
    );
}

export default ImageMagnifier