import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Table } from 'react-bootstrap'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const Enquiries = () => {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()
  const [filter, setFilter] = useState({
    archived: 'N',
    search: '',
  })

  const { data: items } = useQuery({
    queryKey: ['enquiries', filter],
    queryFn: async ({ queryKey }) => {
      return await axiosPrivate
        .post(`/admin/enquiries`, { filter: queryKey[1] })
        .then((res) => res.data)
    },
  })

  const handleArchived = (e) => {
    setFilter({ ...filter, archived: filter.archived === 'Y' ? 'N' : 'Y' })
  }

  const handleUpdateStatus = async (item) => {
    const payload = { ...item }
    payload.archived = payload.archived === 'Y' ? 'N' : 'Y'

    await axiosPrivate.patch(`/admin/enquiries/${payload.id}`, payload)
    queryClient.invalidateQueries({ queryKey: ['enquiries'] })
  }

  return (
    <div>
      <div className="align-items-top row mb-3">
        <div className="col-8">
          <h1 className="h3 mb-0 text-gray-800">Enquiries</h1>
        </div>
        <div className="col-4">
          <div>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search ..."
              onChange={(e) => setFilter({ ...filter, search: e.target.value })}
            />
          </div>

          <div className="mt-1">
            <input
              type="checkbox"
              value="Y"
              checked={filter.archived === 'Y'}
              onChange={handleArchived}
            />{' '}
            Show archived
          </div>
        </div>
      </div>

      <Table striped bordered hover className="shadow">
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Email</th>
            <th>Veneer</th>
            <th>Veneer layout</th>
            <th>Date</th>
            <th className="text-end">Status</th>
          </tr>
        </thead>
        <tbody>
          {items?.length ? (
            <>
              {items.map((item) => (
                <tr key={item.id}>
                  <td className="col-3">
                    <div className="fw-bold">{item.name}</div>
                    <div>{item.ip_addr}</div>
                  </td>
                  <td>
                    <div>{item.email}</div>
                    <div>{item.phone}</div>
                  </td>
                  <td>{item?.veneer_species}</td>
                  <td>{item?.scene_name}</td>
                  <td>{item?.created_at}</td>
                  <td className="text-end">
                    <button
                      className={
                        'btn btn-sm ' +
                        (item?.archived === 'Y' ? 'btn-dark' : 'btn-warning')
                      }
                      onClick={(e) => {
                        e.preventDefault()
                        handleUpdateStatus(item)
                      }}
                    >
                      {item?.archived === 'Y' ? (
                        <div className="">Archived</div>
                      ) : (
                        <div className="">Pending</div>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={6}>No data to display</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default Enquiries
