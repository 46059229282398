import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const ConfirmModal = ({ data, onConfirm, onCancel }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)

  useEffect(() => {
    setShow(data)
  }, [data])

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Veneer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to permanently delete this veneer?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              e.preventDefault()
              onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault()
              onConfirm()
            }}
          >
            Yes, delete
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.preventDefault()
              onCancel()
            }}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmModal
