import axios from '../api/axios'
import useAuth from './useAuth'

const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {
    const response = await axios.get('/auth/refresh', {
      withCredentials: true,
    })

    const ROLES = [1]

    setAuth((prev) => {
      // console.log(JSON.stringify(prev))
      // console.log(response.data.accessToken)
      return {
        ...prev,
        roles: response.data?.roles ?? ROLES,
        accessToken: response.data?.accessToken,
      }
    })
    return response.data.accessToken
  }
  return refresh
}

export default useRefreshToken
