import React from 'react'
import { Outlet } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Helmet } from 'react-helmet'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import './../../../fonts/futura-medium-bt.ttf'
import './../../../fonts/futura-light-bt.ttf'
import './../guest.scss'
import './../_scrollbar.scss'
import { WindowContextProvider } from '../../../context/WindowContextProvider'

const queryClient = new QueryClient()

const RoomLayout = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
      >
        <Helmet>
          <title>Veneer Visualiser</title>
        </Helmet>
        <div id="Guest">
          <WindowContextProvider>
            <Outlet />
          </WindowContextProvider>
        </div>
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  )
}

export default RoomLayout
