import React, { useState, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from '../../api/axios'

const formDataDefault = {
  name: '',
  email: '',
  phone: '',
  company: '',
  title: '',
  message: '',
}

const Contact = () => {

  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(false)
  const [formData, setFormData] = useState(formDataDefault)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false)
    setErrors(null)
    handleReCaptchaVerify()
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('submit_contact_form')
    const payload = {
      ...formData,
      recaptcha: token,
    }

    try {
      await axios.post(`/api/contact`, payload);
      setSuccess(true)
    } catch (err) {
      if (err?.response?.data?.errors) {
        setErrors(err?.response?.data?.errors)
      } else {
        alert('Something went wrong. Please try again later!')
      }
    }
  }, [executeRecaptcha, formData])


  return (
    <section className="main">
      <div className="container">
        <div className="home-intro text-center d-flex flex-column align-items-center justify-content-end mt-5">
          <h1 className="display-1 text-black fw-bold">
            For any enquiries, or to say hello, get in touch
          </h1>
        </div>

        <div className="row mt-5">
          <div className="col-lg-4 col-xs-12 mb-3">
            <div className="fw-bold">Contact info</div>
            <div>
              <div>Email: marketing@dfrichards.co.uk</div>
              <div>Phone: 01268 523 611</div>
            </div>

            <div className="fw-bold">Address:</div>
            <div>
              <div>Kingfisher House, Chester Hall Lane, Basildon SS14 3DA</div>
            </div>
          </div>

          <div className="col-lg-8 col-xs-12">
            {!success ? (
              <form
                onSubmit={onSubmit}
              >

                <div className="row">
                  <div className="col-lg-6 col-xs-12">
                    <div className="form-group">
                      <label>
                        Your Name <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
                    </div>
                    <div className="form-group">
                      <label>
                        Your Phone <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} required />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xs-12">
                    <div className="form-group">
                      <label>
                        Your Email <span className="text-danger">*</span>
                      </label>
                      <input type="email" className="form-control" onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
                    </div>
                    <div className="form-group">
                      <label>Company</label>
                      <input type="text" className="form-control" onChange={(e) => setFormData({ ...formData, company: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    Message <span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                </div>
                <div>


                  {errors && (
                    <div className="alert alert-danger">
                      {Object.keys(errors).map((key) => (
                        <p key={key}>{errors[key][0]}</p>
                      ))}
                    </div>
                  )}

                  <button type="submit" className="btn btn-black">
                    Submit
                  </button>
                </div>
              </form>

            ) : (
              <div className='text-success'>
                Thank you for getting in touch, your message has been sent. We will aim to respond within 2 working days.
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
