import React, { useEffect, useState } from 'react'
// import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import axios from '../../../api/axios'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'
import DownloadPDFModal from '../../../components/DownloadPDFModal'
import ImageMagnifier from '../../../components/ImageMagnifier'

const RoomView = ({ selectedOptions }) => {
  const navigate = useNavigate()
  const [selectedRoom, setSelectedRoom] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchRoom = async () => {
      try {
        const res = await axios.post('/api/rooms/find', {
          options: selectedOptions,
        })
        setSelectedRoom((state) => ({
          ...state,
          ...res.data,
        }))
        setError(false)
      } catch (err) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    fetchRoom()
  }, [selectedOptions])

  if (loading || error) {
    return (
      <div
        className="d-flex justify-content-center"
        style={{ alignItems: 'center', height: '100vh', background: '#ccc' }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <div className="room-container">
      <div className="room-img">
        <ImageMagnifier src={selectedRoom?.preview_url} zoomLevel={1.5} />

        <div className="room-actions">
          <DownloadPDFModal scene={selectedRoom} />
          <button className="btn btn-black" onClick={() => navigate('/')}>
            Change room
          </button>
        </div>
      </div>
    </div>
  )
}

export default RoomView
