import React, { useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNavigate } from 'react-router-dom'
import { LoadingSpinner } from './../../components/LoadingSpinner'
import ConfirmModal from '../../components/ConfirmModal'
import { useQueryClient, useQuery } from 'react-query'
import RegenerateModal from './Veneers/RegenerateModal'

const BASE_URL = process.env.REACT_APP_API

export const Veneers = () => {
  const [deleteVeneer, setDeleteVeneer] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { status: loading, data: veneers } = useQuery({
    queryKey: ['veneers'],
    queryFn: async () => {
      const controller = new AbortController()
      try {
        return await axiosPrivate
          .get('/admin/veneers', {
            signal: controller.signal,
          })
          .then((res) => res.data)
      } catch (err) {
        navigate('/login')
      }
    },
  })

  const handleDelete = async () => {
    try {
      await axiosPrivate.delete('/admin/veneers/' + deleteVeneer.id)
    } catch (err) {
      console.log(err)
    } finally {
      queryClient.invalidateQueries({ queryKey: ['veneers'] })
      setDeleteVeneer(false)
    }
  }


  if (loading === true) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4 align-items-center">
        <h1 className="h3 mb-0 text-gray-800">Veneers</h1>

        <div className='align-items-center'>
          <RegenerateModal onSuccess={() => { }} />

          <Link to={`add`} className='btn btn-link'>
            <FontAwesomeIcon icon={faPlusCircle} size="2x" />
          </Link>
        </div>
      </div>

      <Table striped bordered hover className="shadow">
        <thead>
          <tr>
            <th className="col-2">Thumb</th>
            <th>Name</th>
            <th>Type</th>
            <th>Species</th>
            <th className="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          {veneers?.length ? (
            <>
              {veneers.map((veneer) => (
                <tr key={veneer.id}>
                  <td>
                    {veneer.thumb_url ? (
                      <img
                        src={veneer.thumb_url}
                        className=""
                        alt={veneer.id}
                        height={75}
                      />
                    ) : (
                      <img
                        src={`${BASE_URL}/assets/no-image.webp`}
                        alt={veneer.id}
                        className=""
                        height={75}
                      />
                    )}
                  </td>
                  <td>{veneer.name}</td>
                  <td>{veneer.veneer_type}</td>
                  <td>{veneer.species}</td>
                  <td className="text-end">
                    <div className="btn-group">
                      <Button
                        className="btn btn-danger"
                        onClick={(e) => {
                          e.preventDefault()
                          setDeleteVeneer(veneer)
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                      <Link
                        to={`${veneer.id}/edit`}
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={5}>No data to display</td>
            </tr>
          )}
        </tbody>
      </Table>
      <ConfirmModal
        data={deleteVeneer}
        onConfirm={handleDelete}
        onCancel={() => {
          setDeleteVeneer(false)
        }}
      />

    </div>
  )
}

export default Veneers
