import React from 'react'
import Dropdown from '../../../components/Dropdown'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import VeneerPicker from '../../../components/VeneerPicker'

const RoomOptions = ({ roomOptionsData, onClick, selectedOptions }) => {
  if (!roomOptionsData) {
    return <LoadingSpinner />
  }
  return (
    <div>
      {roomOptionsData.doorTypes ? (
        <div className="mb-3">
          <label className="form-label fw-bold">Choose Door type</label>

          <div className="d-flex justify-content-between mt-1">
            {roomOptionsData.doorTypes.map((doorType) => {
              return (
                <div
                  key={doorType.id}
                  className="p-1"
                  style={{
                    cursor: 'pointer',
                    border:
                      doorType.id === selectedOptions.door_type
                        ? '1px solid black'
                        : '1px solid #ccc',
                  }}
                  onClick={(item) => {
                    onClick('door_type', doorType.id)
                  }}
                >
                  <img src={doorType.thumb_url} alt={doorType.name} />
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        ''
      )}

      {roomOptionsData.veneerTypes ? (
        <div className="mb-3">
          <label className="form-label fw-bold">Choose Veneer type</label>
          <Dropdown
            selected={
              roomOptionsData?.veneerTypes.find(
                (item) => item.id === selectedOptions.veneer_type
              )?.name ?? 'Choose'
            }
            options={roomOptionsData.veneerTypes}
            onClick={(item) => {
              onClick('veneer_type', item.id)
            }}
            className="mt-1"
          />
        </div>
      ) : (
        ''
      )}

      {roomOptionsData.veneers ? (
        <div className="mb-3">
          <label className="form-label fw-bold">Choose Veneer</label>
          <div>
            <VeneerPicker
              veneers={roomOptionsData.veneers}
              onClick={onClick}
              selected={selectedOptions.veneer}
            />
          </div>
        </div>
      ) : (
        ''
      )}

      {roomOptionsData.textureTypes ? (
        <div className="mb-3">
          <label className="form-label fw-bold">Choose Layout</label>
          <Dropdown
            selected={
              roomOptionsData?.textureTypes.find(
                (item) => item.id === selectedOptions.layout
              )?.name ?? 'Choose'
            }
            options={roomOptionsData.textureTypes}
            onClick={(item) => {
              onClick('layout', item.id)
            }}
            className="mt-1"
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default RoomOptions
