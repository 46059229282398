import { Outlet } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import useRefreshToken from '../hooks/useRefreshToken'
import useAuth from '../hooks/useAuth'
import { LoadingSpinner } from './../components/LoadingSpinner'

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  const { auth, persist } = useAuth()

  useEffect(() => {
    let isMounted = true

    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    // console.log('auth', auth)
    // console.log('persist', persist)

    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false)

    return () => (isMounted = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>{!persist ? <Outlet /> : isLoading ? <LoadingSpinner /> : <Outlet />}</>
  )
}

export default PersistLogin
