import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { InputGroup, Card } from 'react-bootstrap'
import { axiosPrivate } from '../../../api/axios'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
const BASE_URL = process.env.REACT_APP_API

const ThumbUpload = ({ id, currentThumb }) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [thumb, setThumb] = useState(false)

  const handleFileInput = async (e) => {
    setLoading(true)
    setError('')
    const file = e.target.files[0]

    // Use the FileReader API to read the contents of the file
    const reader = new FileReader()
    reader.onload = (event) => {
      // Display a preview of the file
      // setPreview(event.target.result);
    }
    reader.readAsDataURL(file)

    // send
    const formData = new FormData()
    formData.append('id', id)
    formData.append('file', file)

    try {
      const veneer = await axiosPrivate
        .post(`${BASE_URL}/admin/veneers/thumb`, formData)
        .then((res) => res.data.veneer)

      if (veneer.thumb_url) {
        setThumb(veneer.thumb_url)
      }
    } catch (err) {
      if (err?.response?.data?.errors) {
        setError('Error: ' + err?.response?.data?.errors.join(''))
      } else if (err?.response?.data?.message) {
        setError('Error: ' + err?.response?.data?.message)
      } else {
        setError('Error: unknown')
      }
      setThumb(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentThumb) {
      setThumb(currentThumb)
    }
  }, [currentThumb])

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Card className="mt-3 shadow">
      <Card.Header>
        <Card.Title className="mb-0">Thumbnail</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col">
            <InputGroup className="mb-3">
              <input
                type="file"
                name="file"
                onChange={(e) => handleFileInput(e)}
              />
            </InputGroup>

            <div className="text-muted bg-light p-3">
              Accepted format: jpg, png, jpeg <br />
              Min width/height: 300px <br />
              Aspect ration: square (width=height) <br />
            </div>

            {error !== '' ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              ''
            )}
          </div>
          <div className="col">
            {!loading && thumb ? (
              <div className="text-center">
                <img src={thumb} alt="Thumb" className="" height={100} />
                <div className="text-muted">actual image</div>
              </div>
            ) : (
              'no thumb uploaded'
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default ThumbUpload
