import React, { useState, useEffect, useCallback } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from '../api/axios'

// const API_BASE_URL = process.env.REACT_APP_API

const formDataDefault = {
  name: '',
  email: '',
  phone: '',
  scene: '',
}

const DownloadPDFModal = ({ scene }) => {
  const [show, setShow] = useState(false)
  const [errors, setErrors] = useState(null)
  const [formData, setFormData] = useState(formDataDefault)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const onSubmit = async (e) => {
    e.preventDefault()
    handleReCaptchaVerify()
  }

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha('submit_enquiry_form')
    // Do whatever you want with the token
    const payload = {
      ...formData,
      scene,
      recaptcha: token,
    }

    try {
      await axios.post(`/api/enquiry`, payload).then((res) => {
        localStorage.setItem(
          'enquiry_data',
          JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
          })
        )

        setShow(false)

        if (res?.data?.pdf_url) {
          return window.open(res.data.pdf_url)
        }
      })
    } catch (err) {
      if (err?.response?.data?.errors) {
        setErrors(err?.response?.data?.errors)
      } else {
        alert('Something went wrong. Please try again later!')
      }
    }
  }, [executeRecaptcha, formData, scene])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  //   handleReCaptchaVerify()
  // }, [handleReCaptchaVerify])

  useEffect(() => {
    let enquiryData = { ...formDataDefault, scene }

    try {
      let enquiryDataFields = JSON.parse(localStorage.getItem('enquiry_data'))
      enquiryData = { ...enquiryData, ...enquiryDataFields }
    } catch (err) {
      enquiryData = { ...formDataDefault, scene }
    }

    setFormData(enquiryData)
    setErrors(null)
  }, [show, scene])

  return (
    <>
      <Button className="btn btn-black" onClick={handleShow}>
        Download PDF
      </Button>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
        <form>
          <Modal.Header closeButton>
            <Modal.Title>Download</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p className='mb-5'>
              You’ve specified {scene?.veneer_name} in {scene?.veneer_layout} layout. To download this CGI render as a PDF please complete the form below.
            </p>

            <div className="form-group">
              <label>
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                type="text"
                className="form-control"
                placeholder="John Snow"
                required
              />
            </div>
            <div className="form-group">
              <label>
                Email <span className="text-danger">*</span>
              </label>
              <input
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                type="email"
                className="form-control"
                placeholder="john.snow@example.com"
                required
              />
            </div>
            <div className="form-group">
              <label>
                Phone <span className="text-danger">*</span>
              </label>
              <input
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
                type="text"
                className="form-control"
                placeholder=""
                required
              />
            </div>

            {errors && (
              <div className="alert alert-danger">
                {Object.keys(errors).map((key) => (
                  <p key={key}>{errors[key][0]}</p>
                ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

            <button
              type="submit"
              onClick={onSubmit}
              className="btn btn-primary"
            >
              Download
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default DownloadPDFModal
