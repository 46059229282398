import React, { useState } from 'react'
import './VeneerRenders.css'
import RoomPreviewModal from './Veneers/RoomPreviewModal'

const VeneerRenders = ({ veneer }) => {
  const [selectedRoom, setSelectedRoom] = useState(false)

  if (!veneer) {
    return <div className="text-danger">No veneer</div>
  }

  return (
    <div id="veneer-edit">
      <div className="font-weight-bold">Rooms</div>
      <div style={{ width: '700px' }}>
        <table className="table table-striped table-inverse table-responsive">
          <thead className="thead-inverse">
            <tr>
              <th>Name</th>
              <th>Scenes</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {veneer.rooms &&
              veneer.rooms.map((room) => (
                <tr key={room.id}>
                  <td>{room?.name}</td>
                  <td>{room?.scenes.length}</td>
                  <td>
                    {room.complete === true ? (
                      <span className="btn btn-success btn-sm">COMPLETE</span>
                    ) : (
                      <span className="btn btn-danger btn-sm">MISSING</span>
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      disabled={!room.complete}
                      className="btn btn-outline-secondary btn-sm"
                      onClick={(e) => {
                        e.preventDefault()
                        setSelectedRoom(room)
                      }}
                    >
                      PREVIEW
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {selectedRoom ? <RoomPreviewModal room={selectedRoom} /> : ''}
    </div>
  )
}

export default VeneerRenders
