import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import Layout from '../pages/Admin/Layout'

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth()
  const location = useLocation()

  // console.log('auth', auth)
  // console.log('allowedRoles', allowedRoles)

  return auth?.roles?.find((role) => allowedRoles?.includes(role)) ? (
    <Layout />
  ) : auth?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequireAuth
