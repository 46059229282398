import React, { useEffect, useRef } from 'react'

const LogData = ({ logs }) => {

    const containerEl = useRef();


    useEffect(() => {
        const scrollToBottom = () => {
            containerEl.current?.scrollIntoView({ behavior: "smooth" });
        }
        scrollToBottom();
    }, [logs])

    return (
        <div>
            <div className='mt-1 bg-light border logData'
                style={{
                    height: '200px',
                    overflow: 'scroll',
                }}>
                {logs && logs.map((item, index) => (
                    <div className='py-1 px-3'
                        style={{
                            borderBottom: '1px solid #ccc',
                            textTransform: 'italic'
                        }}
                        key={index}>
                        {item}
                    </div>
                ))}
                <div ref={containerEl} />
            </div>
        </div>
    )
}

export default LogData