import React from 'react'
import { useQuery } from 'react-query'
import axios from '../../api/axios'
import { Link } from 'react-router-dom'
const API_BASE_URL = process.env.REACT_APP_API

const Home = () => {
  const {
    // status: roomsStatus,
    // error: roomsError,
    data: roomsData,
  } = useQuery({
    queryKey: ['rooms'],
    queryFn: async () => {
      return await axios
        .get(`${API_BASE_URL}/api/rooms`)
        .then((res) => res.data)
    },
  })

  const thumbs = [
    {
      id: 1,
      thumb_url: '5.jpeg',
    },
    {
      id: 2,
      thumb_url: '2.jpeg',
    },
    {
      id: 3,
      thumb_url: '3.jpeg',
    },
    {
      id: 4,
      thumb_url: '4.jpeg',
    },
  ]

  const viewScenarios = () => {
    const element = document.getElementById('scenarios')
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div>
      <section className="main">
        <div className="home-intro text-center d-flex flex-column align-items-center justify-content-end">
          <h1 className="display-1 text-black fw-bold">Try our visualiser</h1>
        </div>

        <div className="container text-center">
          <div className="d-flex align-items-center flex-column">
            <div className="d-flex me-3">
              {thumbs.map((item) => (
                <div
                  className="d-flex align-items-center justify-content-center bg-light rounded-circle mr-n3 border"
                  key={item.id}
                >
                  <img
                    src={`./assets/thumbs/${item.thumb_url}`}
                    alt="Veneer Thumb"
                    width={48}
                    className="rounded-circle"
                  />
                </div>
              ))}
            </div>
            <div className="ms-1 tx-1 mt-3">
              Want to see how your wood veneer will look? <br />
              Select a scenario below and choose from over 200+ veneers
            </div>
          </div>

          <div className="mt-5">
            <button
              type="button"
              className="btn btn-black"
              onClick={viewScenarios}
            >
              View Scenarios
            </button>
          </div>
        </div>
      </section>

      <section className="">
        <div className="bg-black my-5" style={{ height: '2px' }}></div>

        <div className="container" id="scenarios">
          <div className="row">

            {roomsData && roomsData.map((room) => (
            <div className="col-lg-6 col-xs-12" key={room.id}>
              <Link to={`/rooms/${room.slug}`} className="">
                <div className="card card-hover">
                  <div className="position-relative">
                    <img
                      src={`./assets/${room.thumb}`}
                      className="card-img-top"
                      title={room.label}
                      alt={room.label}
                    />

                    <div className="card-img-overlay rounded-3">
                      <span className="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-35 rounded-3"></span>
                    </div>
                  </div>

                  <div className="card-body text-center p-3">
                    <h3 className="fs-5 pt-1 mb-2">{room.label}</h3>
                  </div>
                </div>
              </Link>
            </div>
          ))}

          </div>
        </div>
      </section>
    </div>
  )
}

export default Home
