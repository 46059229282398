import React from 'react'
import { Dropdown } from 'react-bootstrap'

const CustomDropdown = ({ options, selected, onClick, className }) => {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle className="btn btn-block btn-dark border border-1 fw-bold mb-1 rounded-pill">
        {selected}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((item) => (
          <Dropdown.Item
            key={item.id}
            onClick={(e) => {
              e.preventDefault()
              onClick(item)
            }}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CustomDropdown
