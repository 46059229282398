import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useLogout from '../../../hooks/useLogout'
import {
  faBullhorn,
  faList,
  faList12,
  faSignOut,
  faTachometerAlt,
} from '@fortawesome/free-solid-svg-icons'

export const Sidebar = () => {
  const logout = useLogout()
  const navigate = useNavigate()

  const activeClassName = ({ isActive }) => {
    return isActive ? 'active nav-link' : 'nav-link'
  }
  const signOut = async () => {
    await logout()
    navigate('/login')
  }

  return (
    <ul
      className="navbar-nav bg-black sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <a
        className="sidebar-brand d-flex align-items-center justify-content-center"
        href="/admin/dashboard"
      >
        <div className="sidebar-brand-icon rotate-n-15">
          <i className="fas fa-laugh-wink"></i>
        </div>
        <div className="sidebar-brand-text mx-3">
          DoorSim <sup>Admin</sup>
        </div>
      </a>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item">
        <NavLink to={'/admin/dashboard'} className={activeClassName}>
          <FontAwesomeIcon icon={faTachometerAlt} />
          <span className="ml-1">Dashboard</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={'/admin/veneers'} className={activeClassName}>
          <FontAwesomeIcon icon={faList} />
          <span className="ml-1">Veneers</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={'/admin/enquiries'} className={activeClassName}>
          <FontAwesomeIcon icon={faBullhorn} />
          <span className="ml-1">Enquiries</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={'/admin/veneer-types'} className={activeClassName}>
          <FontAwesomeIcon icon={faList12} />
          <span className="ml-1">Veneer Types</span>
        </NavLink>
      </li>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item mt-3">
        <button className="btn btn-link text-danger" onClick={signOut}>
          <FontAwesomeIcon icon={faSignOut} />
          <span className="ml-1">Logout</span>
        </button>
      </li>
    </ul>
  )
}

export default Sidebar
