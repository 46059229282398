import React from 'react'
import { Spinner } from 'react-bootstrap'

export const LoadingSpinner = () => {
  return (
    <div className="text-center">
      <Spinner animation="border" variant="info" />
    </div>
  )
}
