import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

const RoomPreviewModal = ({ room }) => {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)

    useEffect(() => {
        if (room) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [room])

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>{room.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{maxHeight:'70vh',overflow:'scroll'}}>
                {room.scenes.map(scene => (
                    <div key={scene.id} className="mb-3">
                        <img src={scene.base_url} width={2000 / 2.7} height={1500 / 2.7} alt={scene.name} />
                        <div className='d-flex justify-content-around'>
                            <div>{scene.name}</div>
                            <div>
                                <a href={scene.base_url} rel="noreferrer" target="_blank">Preview Full Size</a>
                            </div>
                        </div>
                    </div>
                ))}
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={(e) => {
                        e.preventDefault()
                        setShow(false)
                    }}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RoomPreviewModal
