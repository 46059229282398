import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import VeneerTypesForm from './VeneerTypesForm'
import { useQuery, useQueryClient } from 'react-query'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const VeneerTypes = () => {
  const queryClient = useQueryClient()
  const axiosPrivate = useAxiosPrivate()
  const { status: loading, data: options } = useQuery(
    'veneerTypes',
    async () => {
      return await axiosPrivate
        .get('/admin/veneer-types')
        .then((res) => res.data)
    }
  )

  const [edit, setEdit] = useState()

  const onSave = (e) => {
    setEdit(false)
    queryClient.invalidateQueries('veneerTypes')
  }

  if (edit) {
    return <VeneerTypesForm edit={edit} onSave={onSave} />
  }

  return (
    <div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Veneer Types</h1>
        <button
          className="btn btn-link"
          onClick={(e) => {
            e.preventDefault()
            setEdit({
              id: null,
              name: '',
            })
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="2x" />
        </button>
      </div>

      <div className="">
        {options &&
          options.map((item) => {
            return (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setEdit(item)
                }}
                key={item.id}
                className="btn btn-block btn-outline-secondary text-start mt-2"
              >
                {item.name}
              </button>
            )
          })}
      </div>

      {!loading ? <LoadingSpinner /> : ''}
    </div>
  )
}

export default VeneerTypes
