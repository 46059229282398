import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { Alert, Button, Card, InputGroup, Form } from 'react-bootstrap'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import ThumbUpload from './Veneers/ThumbUpload'
import VeneerUpdate from './Veneers/VeneerUpdate'
import VeneerCrop from './Veneers/VeneerCrop'
import VeneerGenerate from './Veneers/VeneerGenerate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useQuery } from 'react-query'

const BASE_URL = process.env.REACT_APP_API

export const Veneer = () => {
  const { id } = useParams()
  const axiosPrivate = useAxiosPrivate()
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [species, setSpecies] = useState('')
  const [code, setCode] = useState('')
  const [selectedFile, setSelectedFile] = useState('')
  const [veneer, setVeneer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const { status: veneerTypesLoaded, data: veneerTypes } = useQuery(
    'veneerTypes',
    async () => {
      return await axiosPrivate
        .get('/admin/veneer-types')
        .then((res) => res.data)
    }
  )

  const handleFileInput = (e) => {
    const file = e.target.files[0]
    // Use the FileReader API to read the contents of the file
    const reader = new FileReader()
    reader.onload = (event) => {
      // Display a preview of the file
      // setPreview(event.target.result);
    }
    reader.readAsDataURL(file)
    setSelectedFile(file)
  }

  const submitForm = async (e) => {
    e.preventDefault()

    setIsLoading(true)
    setError(null)

    const formData = new FormData()
    formData.append('id', id)
    formData.append('file', selectedFile)
    formData.append('name', name)
    formData.append('species', species)
    formData.append('code', code)
    formData.append('type', type)

    try {
      const res = await axiosPrivate.post(
        BASE_URL + '/admin/veneers/upload',
        formData
      )
      setVeneer(res.data.veneer)

      if (res.data.veneer.id) {
        navigate(`/admin/veneers/${res.data.veneer.id}/edit`, { replace: true })
      }
      if (res.data.veneer.id) {
        navigate(`/admin/veneers/${res.data.veneer.id}/edit`, { replace: true })
      }
    } catch (err) {
      console.dir(err)

      if (err.data) {
        setError(err.data)
      } else if (err.response.data.errors) {
        setError(err.response.data.errors)
      } else if (err.response.data.message) {
        setError(err.response.data.message)
      } else {
        setError('upload error')
      }
      setVeneer(null)
    } finally {
      setIsLoading(false)
    }
  }

  let displayVeneer = ''
  useEffect(() => {
    // let isMounted = true
    const controller = new AbortController()
    const fetchVeneer = async () => {
      const veneer = await axiosPrivate
        .get(BASE_URL + '/admin/veneers/' + id)
        .then((res) => res.data)

      if (id && !veneer) {
        navigate('/admin/veneers', { replace: true })
      }
      setName(veneer.name)
      setSpecies(veneer.species)
      setType(veneer.type)
      setCode(veneer.code)
      setType(veneer.type)
      setVeneer(veneer)
    }

    if (id) {
      fetchVeneer()
    }
    return () => {
      // isMounted = false
      controller.abort()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">
          {id ? 'Edit Veneer' : 'Add Veneer'}
        </h1>

        <Link to="/admin/veneers" className="btn btn-link">
          <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" />
        </Link>
      </div>

      <VeneerUpdate data={veneer} />
      <VeneerCrop veneer={veneer} setVeneer={setVeneer} />

      <form>
        {!id ? (
          <Card className="mt-3 shadow">
            <Card.Header>
              <Card.Title>Details</Card.Title>
            </Card.Header>
            <Card.Body>
              {!veneer || !veneer.id ? (
                <>
                  <InputGroup className="mb-3">
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => handleFileInput(e)}
                    />
                  </InputGroup>

                  <div className="text-muted fst-italic">
                    Allowed format: png, jpg, jpeg <br />
                    Required size: 3000x200px OR 200x3000px
                  </div>
                </>
              ) : (
                ''
              )}

              <Form.Group className="mb-3 mt-3">
                <Form.Label>
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              {veneerTypesLoaded && veneerTypes ? (
                <Form.Group className="mb-3">
                  <Form.Label>
                    Type <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Select
                    aria-label="Type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option>Choose</option>
                    {veneerTypes &&
                      veneerTypes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                <LoadingSpinner />
              )}

              <Form.Group className="mb-3">
                <Form.Label>
                  Code <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Species <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={species}
                  onChange={(e) => setSpecies(e.target.value)}
                />
              </Form.Group>

              {error ? (
                <Alert className="mt-3" variant="danger">
                  {error}
                </Alert>
              ) : (
                ''
              )}
            </Card.Body>
            <Card.Footer>
              <Button
                variant="primary"
                onClick={submitForm}
                disabled={!selectedFile && !id}
              >
                Save
              </Button>
            </Card.Footer>
          </Card>
        ) : (
          <ThumbUpload id={id} currentThumb={veneer?.thumb_url} />
        )}
        {displayVeneer}

        {veneer ? <VeneerGenerate veneer={veneer} setVeneer={setVeneer} /> : ''}
      </form>
    </div>
  )
}

export default Veneer
