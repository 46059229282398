import React, { useState } from 'react'
import { useEffect } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

const BASE_URL = process.env.REACT_APP_API

const VeneerUpdate = ({ data }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const axiosPrivate = useAxiosPrivate()

  const [formData, setFormData] = useState({
    id: '',
    name: '',
    species: '',
    code: '',
    type: '',
  })

  const { status: veneerTypesLoaded, data: veneerTypes } = useQuery(
    'veneerTypes',
    async () => {
      return await axiosPrivate
        .get('/admin/veneer-types')
        .then((res) => res.data)
    }
  )

  const onSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)
    setError(false)

    try {
      await axiosPrivate.patch(
        `${BASE_URL}/admin/veneers/${formData.id}`,
        formData
      )
      // console.log(res)
      // setVeneer(res.data.veneer)
    } catch (err) {
      console.dir(err)

      if (err.data) {
        setError(err.data)
      } else if (err.response.data.errors) {
        setError(err.response.data.errors)
      } else if (err.response.data.message) {
        setError(err.response.data.message)
      } else {
        setError('upload error')
      }
      // setVeneer(null)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log(data)
    setFormData({ ...data })
  }, [data])

  if (!data?.id) {
    return ''
  }

  return (
    <Form onSubmit={onSubmit}>
      <Card className="mt-3 shadow">
        <Card.Header>
          <Card.Title className="mb-1">General Details</Card.Title>
        </Card.Header>
        <Card.Body>
          {!loading ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Name"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value })
                  }}
                />
              </Form.Group>
              {veneerTypesLoaded && veneerTypes ? (
                <Form.Group className="mb-3">
                  <Form.Label>
                    Type <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Select
                    aria-label="Type"
                    value={formData.type}
                    onChange={(e) =>
                      setFormData({ ...formData, type: e.target.value })
                    }
                  >
                    <option>Choose</option>
                    {veneerTypes &&
                      veneerTypes.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              ) : (
                <LoadingSpinner />
              )}

              <Form.Group className="mb-3">
                <Form.Label>
                  Code <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={formData.code}
                  onChange={(e) =>
                    setFormData({ ...formData, code: e.target.value })
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>
                  Species <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  value={formData.species}
                  onChange={(e) =>
                    setFormData({ ...formData, species: e.target.value })
                  }
                />
              </Form.Group>
            </>
          ) : (
            <LoadingSpinner />
          )}

          {error ? (
            <Alert className="mt-3" variant="danger">
              {error}
            </Alert>
          ) : (
            ''
          )}
        </Card.Body>
        <Card.Footer>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  )
}

export default VeneerUpdate
