import React, { useState } from 'react'
import { Card, Alert, Button, Image } from 'react-bootstrap'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { axiosPrivate } from '../../../api/axios'
import { LoadingSpinner } from '../../../components/LoadingSpinner'

const BASE_URL = process.env.REACT_APP_API
const scaleFactor = 2
const cropDefault = {
  unit: 'px',
  x: 0,
  y: 0,
  width: 1000,
  height: 100,
}

const VeneerCrop = ({ veneer, setVeneer }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      await axiosPrivate.post(BASE_URL + '/admin/veneers/crop', veneer)
    } catch (err) {
      setVeneer({ ...veneer, crop_data: cropDefault })
      setError(err)
    } finally {
      setLoading(false)
    }
  }

  if (!veneer) {
    return ''
  }

  return (
    <Card className="mt-3 shadow">
      <Card.Header>
        <Card.Title>Crop</Card.Title>
      </Card.Header>
      <Card.Body>
        {!loading ? (
          <>
            <div
              style={{
                width: '1500px',
                height: '130px',
              }}
            >
              <div className="text-muted">
                Click and drag the crop horizontally.
              </div>
              <ReactCrop
                crop={veneer?.crop_data}
                onChange={(c) => {
                  setVeneer({ ...veneer, crop_data: { ...c, scaleFactor } })
                }}
                keepSelection={true}
                locked={true}
                minWidth={2000 / scaleFactor}
                aspect={2000 / 200}
              >
                <Image src={veneer?.base_url} height={200 / scaleFactor} />
              </ReactCrop>
            </div>
            <div>
              X axis offset: <b>{Math.round(veneer?.crop_data?.x)} px</b>
            </div>
          </>
        ) : (
          <LoadingSpinner />
        )}

        {error ? (
          <Alert className="mt-3" variant="danger">
            {error}
          </Alert>
        ) : (
          ''
        )}
      </Card.Body>
      <Card.Footer>
        <Button variant="primary" onClick={onSubmit}>
          Crop
        </Button>
      </Card.Footer>
    </Card>
  )
}

export default VeneerCrop
