import React from 'react'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'
import Sidebar from './Layout/Sidebar'
import Topbar from './Layout/Topbar'

import 'bootstrap/dist/css/bootstrap.min.css'
import './admin.scss'

const queryClient = new QueryClient()

const Layout = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div id="Admin">
        <Helmet>
          <title>Admin - DoorSim</title>
        </Helmet>

        <div id="wrapper">
          <Sidebar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div className="content">
              <Topbar />
              <div className="container-fluid">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </QueryClientProvider>
  )
}

export default Layout
