import React, { createContext, useContext, useState } from 'react'

const WindowContext = createContext({});

export const WindowContextProvider = ({ children }) => {
    
    const [sidebar, setSidebar] = useState({
        width: 250,
    })

    return (
        <WindowContext.Provider value={{ sidebar, setSidebar }}>
          {children}
        </WindowContext.Provider>
      )
}

export const useWindowContext = () => useContext(WindowContext)