import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import axios from '../../api/axios'
import { axiosPrivate } from '../../api/axios'
import { useNavigate } from 'react-router-dom'

const LOGIN_URL = '/auth/login'
const Login = () => {
  const { setAuth, persist, setPersist } = useAuth()

  const navigate = useNavigate()
  // const location = useLocation()
  // const from = location.state?.from?.pathname || '/'

  const userRef = useRef()
  const errRef = useRef()

  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    userRef.current.focus()
    if (success) {
      navigate('/admin/dashboard', { replace: true })
    }
  }, [success, navigate])

  useEffect(() => {
    setErrMsg('')
  }, [user, password])

  useEffect(() => {
      axiosPrivate.get('/admin/users/me').then(res => {
        console.log(res);
      })

    // if (user) {
    //   console.log(user)
    //   // navigate('/admin/dashboard')
    // }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email: user, password }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      // console.log(response)
      //console.log(JSON.stringify(response));
      const accessToken = response?.data?.accessToken

      // set default to admin (1)
      const roles = [1]
      setAuth({ user, password, roles, accessToken })
      setUser('')
      setPassword('')
      setSuccess(true)
    } catch (err) {
      console.dir(err)

      if (!err?.response) {
        setErrMsg('No Server Response')
      } else if (err.response?.status === 400) {
        setErrMsg(err.response.data.message)
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg('Login Failed')
      }
      errRef.current.focus()
    }
  }

  const togglePersist = () => {
    setPersist((prev) => !prev)
  }

  useEffect(() => {
    localStorage.setItem('persist', persist)
  }, [persist])

  return (
    <div className="row justify-content-center">
      <div className="col-xl-10 col-lg-12 col-md-9">
        <div className="card o-hidden border-0 shadow-lg my-5">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
              <div className="col-lg-6">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                  </div>
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-user"
                        placeholder="Enter Email Address..."
                        name="email"
                        ref={userRef}
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        name="password"
                        className="form-control form-control-user"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="checkbox"
                        id="persist"
                        onChange={togglePersist}
                        checked={persist}
                      />
                      <label htmlFor="persist">Trust This Device</label>
                    </div>

                    <p
                      ref={errRef}
                      className={errMsg ? 'text-danger' : 'offscreen'}
                      aria-live="assertive"
                    >
                      {errMsg}
                    </p>

                    <button
                      type="submit"
                      className="btn btn-primary btn-user btn-block"
                    >
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
