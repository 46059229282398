import React, { Routes, Route } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Dashboard from './pages/Admin/Dashboard'
import Veneer from './pages/Admin/Veneer'
import Veneers from './pages/Admin/Veneers'
import VeneerTypes from './pages/Admin/VeneerTypes/VeneerTypes'
import VeneerTypesForm from './pages/Admin/VeneerTypes/VeneerTypesForm'
import Enquiries from './pages/Admin/Enquiries'
import Login from './pages/Admin/Login'
import NoPage from './pages/Admin/NoPage'
import Unauthorized from './pages/Admin/Unauthorized'
import RequireAuth from './components/RequireAuth'
import PersistLogin from './components/PersistLogin'

import GuestLayout from './pages/Guest/Layout/HomePageLayout2'
import Home from './pages/Guest/Home'
import Contact from './pages/Guest/Contact'
import RoomLayout from './pages/Guest/Layout/RoomLayout'
import Room from './pages/Guest/Room'

const ROLES = {
  Admin: 1,
}

const App = () => {
  return (
    <>
      <Routes>
        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route
            path="/admin"
            element={<RequireAuth allowedRoles={[ROLES.Admin]} />}
          >
            <Route path="dashboard" index element={<Dashboard />} />
            <Route path="veneers" element={<Veneers />} />
            <Route path="veneers/add" element={<Veneer />} />
            <Route path="veneers/:id/edit" element={<Veneer />} />
            <Route path="enquiries" element={<Enquiries />} />
            <Route path="veneer-types/:id/edit" element={<VeneerTypesForm />} />
            <Route path="veneer-types" element={<VeneerTypes />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Route>
        <Route path="/" element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}><GuestLayout /></GoogleReCaptchaProvider>}>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
        <Route path="/rooms" element={<RoomLayout />}>
          <Route path="/rooms/:slug" element={<Room />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
      </Routes>
    </>
  )
}

export default App
